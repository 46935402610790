import React from "react"
import Helmet from "react-helmet"

// markup
const ProviderPage = () => {

    return (
        <>
            <Helmet>
                <title>Find a Provider | Careway</title>
            </Helmet>
            <iframe title="Provider Find Map" src="https://hub.careway.co.nz/provider-public/" style={{height: "1100px", width:"100%", marginTop: "20px"}} />
        </>
    )
}

export default ProviderPage
